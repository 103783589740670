import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import hosts from "../assets/hosts.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiDeletePersona,
  apiUpdatePersona,
  apiUploadFile,
  apiUploadPersonaImage as apiGetPersonaImageUploadUrl,
  apiUploadPersonaAudio as apiGetPersonaAudioUploadUrl,
  useApiPersona,
  useApiVoices,
} from "../api/api";
import { RefObject, useEffect, useRef, useState } from "react";
import LargeButton from "../components/input/LargeButton";
import Select, { MultiValue, StylesConfig } from "react-select";
import ImagePicker from "../components/input/ImagePicker";
import "./HostPage.css";
import dropdownIcon from "../assets/dropdown.png";
import Modal from "../components/layout/Modal";
import HostStageItem from "../components/input/HostStageItem";
import AudioPicker from "../components/input/AudioPicker";
import MultiselectAccordionGooglePlacesTypes, {
  GooglePlacesTypeGroups,
} from "../components/input/MultiselectAccordionGooglePlacesTypes";
import TabContainer from "../components/layout/TabContainer";
import HostPlayerMap, {
  StoryType,
} from "../components/host/player/hostPlayerMap/hostPlayerMap";

interface OptionType {
  value: string;
  label: string;
}

const dropdownStylesMulti: StylesConfig<OptionType, true> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
  }),
  control: (provided) => ({
    ...provided,
    background: "#242424",
    borderColor: "#F0EDE033",
    borderRadius: 8,
    minHeight: "24px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#060606" : "#242424",
    color: state.isSelected ? "#ffffff" : "#cccccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff", // This sets the color of the selected item text to white
  }),
  menu: (provided) => ({
    ...provided,
    // Adjust these values as needed to change the overall menu background
    backgroundColor: "#242424", // Change this to match your desired background color
    padding: 0, // Set to 0 or adjust as needed to remove unwanted space
    borderRadius: "8px", // Optional: to match the control's borderRadius
  }),
};

const dropdownStyles: StylesConfig<OptionType, false> = {
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    minWidth: "250px",
  }),
  control: (provided) => ({
    ...provided,
    background: "#242424",
    borderColor: "#F0EDE033",
    borderRadius: 8,
    minHeight: "24px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#060606" : "#242424",
    color: state.isSelected ? "#ffffff" : "#cccccc",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#ffffff", // This sets the color of the selected item text to white
  }),
  menu: (provided) => ({
    ...provided,
    // Adjust these values as needed to change the overall menu background
    backgroundColor: "#242424", // Change this to match your desired background color
    padding: 0, // Set to 0 or adjust as needed to remove unwanted space
    borderRadius: "8px", // Optional: to match the control's borderRadius
  }),
};

function HostPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const host = useApiPersona(id!);
  const maxDescLength = 400;
  const voices = useApiVoices();
  const [hostGenreTag1, setHostGenreTag1] = useState("");
  const [hostGenreTag2, setHostGenreTag2] = useState("");
  const [hostGenreTag3, setHostGenreTag3] = useState("");
  const [hostName, setHostName] = useState("");
  const [hostDesc, setHostDesc] = useState("");
  const [hostPrompt, setHostPrompt] = useState("");
  const [selectedPoiPrompt, setSelectedPoiPrompt] = useState("");
  const [surprisePrompt, setSurprisePrompt] = useState("");
  const [suggestionsPrompt, setSuggestionsPrompt] = useState("");
  const [followUpPrompt, setFollowUpPrompt] = useState("");
  const [hostStage, setHostStage] = useState("draft");
  const [pendingSave, setPendingSave] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
  const [selectedVoice, setSelectedVoice] = useState<OptionType | null>(null);
  const [selectedTextModel, setSelectedTextModel] = useState<OptionType | null>(
    null
  );
  const [selectedVoiceModel, setSelectedVoiceModel] =
    useState<OptionType | null>(null);
  const [selectedSubType, setSubType] = useState<OptionType | null>(null);
  const [hostVoiceStability, setHostVoiceStability] = useState(0);
  const [hostVoiceSimilarityBoost, setHostVoiceSimilarityBoost] = useState(0);
  const [hostVoiceStyle, setHostVoiceStyle] = useState(0);
  const [hostSpeakerBoost, setHostSpeakerBoost] = useState(false);
  const [webSearchEnabled, setWebSearchEnabled] = useState(false);
  const [webSearchQueryTemplate, setWebSearchQueryTemplate] = useState("");
  const [selectedWebSearchTopic, setSelectedWebSearchTopic] =
    useState<OptionType | null>(null);
  const [webSearchIncludeDomains, setWebSearchIncludeDomains] = useState<
    string[]
  >([]);
  const [webSearchExcludeDomains, setWebSearchExcludeDomains] = useState<
    string[]
  >([]);
  const [webSearchIncludeDomainsInput, setWebSearchIncludeDomainsInput] =
    useState("");
  const [webSearchExcludeDomainsInput, setWebSearchExcludeDomainsInput] =
    useState("");
  const QueryTemplateRef = useRef<HTMLTextAreaElement>(null);
  const IncludeDomainsRef = useRef<HTMLTextAreaElement>(null);
  const ExcludeDomainsRef = useRef<HTMLTextAreaElement>(null);

  const [selectedPoiTypes, setSelectedPoiTypes] = useState<
    MultiValue<OptionType>
  >([]);

  const handlePoiTypesChange = (selected: MultiValue<OptionType>) => {
    setPendingSave(true);
    setSelectedPoiTypes(selected);
  };

  const handleHostSpeakerBoostChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHostSpeakerBoost(event.target.checked);
    setPendingSave(true);
  };

  const handleHostVoiceStyleChange = (event: { target: { value: any } }) => {
    setHostVoiceStyle(Number(event.target.value));
    setPendingSave(true);
  };
  const handleHostSimilarityBoostChange = (event: {
    target: { value: any };
  }) => {
    setHostVoiceSimilarityBoost(Number(event.target.value));
    setPendingSave(true);
  };
  const handleHostVoiceStabilityChange = (event: {
    target: { value: any };
  }) => {
    setHostVoiceStability(Number(event.target.value));
    setPendingSave(true);
  };

  const handleValidImageSelected = (file: File) => {
    setSelectedImageFile(file);
    setPendingSave(true);
  };
  const handleValidAudioSelected = (file: File) => {
    setSelectedAudioFile(file);
    setPendingSave(true);
  };

  const handleWebSearchEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWebSearchEnabled(event.target.checked);
    setPendingSave(true);
  };
  const handleWebSearchQueryTemplateChange = (event: {
    target: { value: any };
  }) => {
    adjustTextareaHeight(QueryTemplateRef);
    setWebSearchQueryTemplate(event.target.value);
  };

  const handleWebSearchIncludeDomainsInputChange = (event: {
    target: { value: any };
  }) => {
    setWebSearchIncludeDomainsInput(event.target.value);
    adjustTextareaHeight(IncludeDomainsRef);
    const domainArray = event.target.value
      .split(" ")
      .map((domain: string) => domain.trim())
      .filter((domain: string) => domain !== "");
    setWebSearchIncludeDomains(domainArray);
  };
  const handleWebSearchExcludeDomainsInputChange = (event: {
    target: { value: any };
  }) => {
    setWebSearchExcludeDomainsInput(event.target.value);
    adjustTextareaHeight(ExcludeDomainsRef);
    const domainArray = event.target.value
      .split(" ")
      .map((domain: string) => domain.trim())
      .filter((domain: string) => domain !== "");
    setWebSearchExcludeDomains(domainArray);
  };

  const adjustTextareaHeight = (
    textareaRef: RefObject<HTMLTextAreaElement>
  ) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const savedIncludeDomains = host.data?.webSearchIncludeDomains ?? [];
    setWebSearchIncludeDomains(savedIncludeDomains);
    const savedExcludeDomains = host.data?.webSearchExcludeDomains ?? [];
    setWebSearchExcludeDomains(savedExcludeDomains);
    if (savedIncludeDomains.length > 0) {
      setWebSearchIncludeDomainsInput(savedIncludeDomains.join(" "));
    }
    if (savedExcludeDomains.length > 0) {
      setWebSearchExcludeDomainsInput(savedExcludeDomains.join(" "));
    }
  }, [host.data]);

  useEffect(() => {
    adjustTextareaHeight(IncludeDomainsRef);
    adjustTextareaHeight(ExcludeDomainsRef);
    adjustTextareaHeight(QueryTemplateRef);
  }, [
    webSearchIncludeDomainsInput,
    webSearchExcludeDomainsInput,
    webSearchQueryTemplate,
  ]);

  useEffect(() => {
    if (host.data != null && voices.data != null) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [host.data, voices.data]);

  function getHostStage(key: string): string {
    switch (key) {
      case "prod":
        return "Production";
      case "staging":
        return "Staging";
      default:
        return "Draft";
    }
  }

  function webSearchTopicOptions() {
    return [
      { label: "general", value: "general" },
      { label: "news", value: "news" },
    ];
  }
  function textModelOptions() {
    return [
      { label: "gpt-4-omni-mini", value: "gpt-4o-mini" },
      { label: "gpt-3.5-turbo", value: "gpt-3.5-turbo" },
      { label: "gpt-4-turbo", value: "gpt-4-turbo" },
      { label: "gpt-4-omni", value: "gpt-4o" },
      { label: "claude-3-haiku", value: "claude-3-haiku" },
      { label: "claude-3-sonnet", value: "claude-3-sonnet" },
      { label: "llama-3.1-405b", value: "llama-3.1-405b" },
      { label: "llama-3.1-70b", value: "llama-3.1-70b" },
      { label: "llama-3.1-8b", value: "llama-3.1-8b" },
      { label: "cohere-command-r", value: "cohere-command-r" },
      { label: "cohere-command-r-plus", value: "cohere-command-r-plus" },
    ];
  }
  function voiceModelOptions() {
    return [
      { label: "eleven_multilingual_v2", value: "eleven_multilingual_v2" },
      { label: "eleven_turbo_v2_5", value: "eleven_turbo_v2_5" },
      { label: "eleven_turbo_v2", value: "eleven_turbo_v2" },
      { label: "eleven_monolingual_v1", value: "eleven_monolingual_v1" },
      { label: "eleven_multilingual_v1", value: "eleven_multilingual_v1" },
    ];
  }

  function subTypeOptions() {
    return [
      { label: "Premium - Needs to be purchased separately", value: "premium" },
      { label: "Free - Unlimited use for everyone", value: "free" },
    ];
  }

  function voiceOptions() {
    var result: OptionType[] = [];
    if (voices.data) {
      voices.data.forEach((voice) => {
        result.push({ value: voice.voiceId.toString(), label: voice.name });
      });
    }
    return result;
  }

  function getGenreTagsArray(): string[] {
    var result = [];

    if (hostGenreTag1.length > 0) result.push(hostGenreTag1);
    if (hostGenreTag2.length > 0) result.push(hostGenreTag2);
    if (hostGenreTag3.length > 0) result.push(hostGenreTag3);
    return result;
  }

  function init() {
    setHostDesc(host.data?.description ?? "");
    setHostName(host.data?.name ?? "");
    setHostPrompt(host.data?.promptTemplateHost?.replace(/\\n/g, "\n") ?? "");

    setSurprisePrompt(
      host.data?.promptTemplatePoiMulti?.replace(/\\n/g, "\n") ?? ""
    );
    setSuggestionsPrompt(
      host.data?.promptTemplateFollowUpSuggestions?.replace(/\\n/g, "\n") ?? ""
    );

    setFollowUpPrompt(
      host.data?.promptTemplateFollowUp?.replace(/\\n/g, "\n") ?? ""
    );

    setSelectedPoiPrompt(
      host.data?.promptTemplatePoiSingle?.replace(/\\n/g, "\n") ?? ""
    );

    setHostGenreTag1(host.data?.genreTags[0] ?? "");
    setHostGenreTag2(host.data?.genreTags[1] ?? "");
    setHostGenreTag3(host.data?.genreTags[2] ?? "");
    setHostStage(host.data?.stage ?? "draft");

    if (host.data?.poiTypes) {
      const initialSelectedOptions = GooglePlacesTypeGroups.map(
        (g) => g.options
      )
        .flat()
        .filter((option) => host.data?.poiTypes.includes(option.value));
      setSelectedPoiTypes(initialSelectedOptions);
    }

    setHostVoiceSimilarityBoost(host.data?.voiceSimilarityBoost ?? 0);
    setHostVoiceStyle(host.data?.voiceStyle ?? 0);
    setHostVoiceStability(host.data?.voiceStability ?? 0);
    setHostSpeakerBoost(host.data?.useSpeakerBoost ?? false);

    setWebSearchEnabled(host.data?.webSearchEnabled ?? false);
    setWebSearchQueryTemplate(host.data?.webSearchQueryTemplate ?? "");
    setSelectedWebSearchTopic(
      webSearchTopicOptions().find(
        (it) => it.value === host.data?.webSearchTopic
      ) ?? webSearchTopicOptions()[0]
    );
    setWebSearchIncludeDomains(host.data?.webSearchIncludeDomains ?? []);
    setWebSearchExcludeDomains(host.data?.webSearchExcludeDomains ?? []);

    setSelectedTextModel(
      textModelOptions().find((it) => it.value === host.data?.textModel) ??
        textModelOptions()[0]
    );
    setSelectedVoiceModel(
      voiceModelOptions().find((it) => it.value === host.data?.voiceModel) ??
        voiceModelOptions()[0]
    );

    setSubType(
      subTypeOptions().find((it) => it.value === host.data?.subscriptionType) ??
        subTypeOptions()[0]
    );

    if (host.data?.voiceId && voices.data) {
      setSelectedVoice({
        value: host.data.voiceId,
        label:
          voices.data.find((it) => it.voiceId.toString() === host.data?.voiceId)
            ?.name ?? "NOT FOUND",
      });
    } else {
      console.log("missing voiceid");
    }
  }

  function savePersona(pictureUrl?: string | null, audioUrl?: string | null) {
    if (selectedPoiTypes.length === 0) {
      alert(
        "Could not save without POI types. Please select at least one and try again."
      );
      return;
    }

    apiUpdatePersona({
      ...host.data!,
      stage: hostStage,
      subscriptionType: selectedSubType?.value ?? "free",
      name: hostName,
      description: hostDesc,
      promptTemplateHost: hostPrompt,
      promptTemplatePoiSingle: selectedPoiPrompt,
      promptTemplatePoiMulti: surprisePrompt,
      promptTemplateFollowUpSuggestions: suggestionsPrompt,
      promptTemplateFollowUp: followUpPrompt,
      voiceId: selectedVoice?.value,
      voiceSimilarityBoost: hostVoiceSimilarityBoost,
      voiceStyle: hostVoiceStyle,
      voiceStability: hostVoiceStability,
      poiTypes: selectedPoiTypes.map((it) => it.value),
      genreTags: getGenreTagsArray(),
      textModel: selectedTextModel?.value ?? textModelOptions()[0].value,
      voiceModel: selectedVoiceModel?.value ?? voiceModelOptions()[0].value,
      useSpeakerBoost: hostSpeakerBoost,
      pictureUrl: pictureUrl ?? host.data!.pictureUrl ?? "",
      audioSampleUrl: audioUrl ?? host.data!.audioSampleUrl ?? "",
      webSearchEnabled: webSearchEnabled,
      webSearchQueryTemplate: webSearchQueryTemplate,
      webSearchTopic:
        selectedWebSearchTopic?.value ?? webSearchTopicOptions()[0].value,
      webSearchIncludeDomains: webSearchIncludeDomains,
      webSearchExcludeDomains: webSearchExcludeDomains,
    }).then((response) => {
      if (response.error) alert("failed to save");
      setSelectedImageFile(null);
      setSelectedAudioFile(null);
      host.refresh();
      voices.refresh();
      setPendingSave(false);
    });
  }

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={hosts}
          alt="hosts"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          <span style={{ color: "#84FF81" }}>Hosts</span>
          <span>{" / " + hostName}</span>
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        >
          <div
            className="host-stage-selector"
            onClick={() => {
              setShowPublishDialog(true);
            }}
          >
            <p
              style={
                hostStage !== "prod"
                  ? { color: "#ED4F4F" }
                  : { color: "#84FF81" }
              }
            >
              • {getHostStage(hostStage)}
            </p>
            <div style={{ width: 20 }}></div>
            <img src={dropdownIcon} alt="icon" height={7} />
          </div>
          {pendingSave && (
            <LargeButton
              text="Save Changes"
              onClick={async () => {
                var imageUploadUrl = null;
                var audioUploadUrl = null;

                if (host.data) {
                  if (
                    host.data.subscriptionType === "free" &&
                    selectedSubType?.value === "premium"
                  ) {
                    const isConfirmed = window.confirm(
                      "***WARNING***\n\nYou are about to switch the host to Premium.\n\nRemember to create the product in Apple AppStoreConnect before switching the host to Premium.\n\nAre you sure you want to continue?"
                    );
                    if (!isConfirmed) {
                      return;
                    }
                  }
                }

                if (selectedImageFile != null) {
                  const imageUpload = await apiGetPersonaImageUploadUrl(
                    selectedImageFile.name
                  );
                  if (imageUpload.error) {
                    alert("Failed to get image upload url");
                    return;
                  }
                  const upload = await apiUploadFile(
                    imageUpload.data!.upload,
                    selectedImageFile
                  );
                  if (upload.error) {
                    alert("Failed to upload image");
                    return;
                  }
                  imageUploadUrl = imageUpload.data!.url;
                }

                if (selectedAudioFile != null) {
                  const audioUpload = await apiGetPersonaAudioUploadUrl(
                    selectedAudioFile.name
                  );
                  if (audioUpload.error) {
                    alert("Failed to get audio upload url");
                    return;
                  }
                  const upload = await apiUploadFile(
                    audioUpload.data!.upload,
                    selectedAudioFile
                  );
                  if (upload.error) {
                    alert("Failed to upload audio");
                    return;
                  }
                  audioUploadUrl = audioUpload.data!.url;
                }

                savePersona(imageUploadUrl, audioUploadUrl);
              }}
              icon={null}
              color={"#34A853"}
            />
          )}
          {pendingSave && (
            <div
              style={{
                borderRight: "1px solid #333333",
                boxSizing: "border-box",
                paddingLeft: "12px",
                marginRight: "12px",
                marginTop: 8,
                marginBottom: 8,
              }}
            ></div>
          )}
          <LargeButton
            text="Delete"
            outlineOnly={true}
            onClick={() => {
              const isConfirmed = window.confirm(
                "***WARNING***\n\nYou are about to delete a Persona.\n\nDeleting a Persona is irreversible and will remove all generated stories and data associated with this Persona.\n\nAre you sure you want to delete this Persona?"
              );
              if (isConfirmed) {
                setPendingSave(false);
                apiDeletePersona(host.data!.id)
                  .then(() => {
                    navigate("/hosts/");
                  })
                  .catch((error) => {
                    alert("Failed to delete persona:" + error);
                  });
              }
            }}
            icon={null}
            color={"red"}
          />
        </div>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={0} />
      <div className="content">
        <div className="host-header">
          <div className="host-image-wrapper">
            <h3 style={{ marginBottom: 18 }}>Host Avatar</h3>
            <div
              style={{
                width: 200,
                height: 200,
                backgroundColor: "#242424",
                borderRadius: 32,
                overflow: "hidden",
                marginBottom: 16,
              }}
            >
              {selectedImageFile == null && !host.data?.pictureUrl && (
                <p
                  style={{ textAlign: "center", marginTop: 91, color: "gray" }}
                >
                  No image
                </p>
              )}
              {selectedImageFile != null && (
                <img
                  src={URL.createObjectURL(selectedImageFile)}
                  alt="Selected"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
              {selectedImageFile == null && host.data?.pictureUrl && (
                <img
                  src={host.data!.pictureUrl}
                  alt="Selected"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </div>
            <ImagePicker
              onValidImageSelected={handleValidImageSelected}
            ></ImagePicker>
            <small>
              <b>Image requirements</b>
            </small>
            <br />
            <small>Use png format</small>
            <br />
            <small>Must be at least 200px x 200 px</small>
          </div>
          <div className="host-name-wrapper">
            <h3>Host Name</h3>
            <small>
              This will be displayed in the app wherever the host is visible
            </small>
            <br />
            <input
              type="text"
              value={hostName}
              onChange={(value) => {
                setHostName(value.currentTarget.value);
                setPendingSave(true);
              }}
              style={{
                fontSize: 22,
                fontWeight: 600,
                outline: "none",
                marginTop: 8,
                boxSizing: "border-box",
                width: "100%",
                padding: 12,
              }}
            />

            <h3>Host subscription type</h3>
            <small>Select who can use this host.</small>
            <div style={{ marginTop: 8 }}>
              <Select
                value={selectedSubType}
                onChange={(newValue) => {
                  setSubType(newValue);
                  setPendingSave(true);
                }}
                options={subTypeOptions()}
                styles={dropdownStyles}
              />
            </div>
          </div>
        </div>
        <TabContainer
          tabs={[
            "Info",
            "Audio",
            "Global Attributes",
            "Surprise Me",
            "POI Rabbithole",
          ]}
          className="host-tab-container"
        >
          {/* -----Info------ */}
          <>
            <h3>Host Description</h3>
            <div className="row">
              <div>
                <textarea
                  value={hostDesc}
                  maxLength={maxDescLength}
                  style={{ marginTop: 8 }}
                  onChange={(value) => {
                    setHostDesc(value.currentTarget.value);
                    setPendingSave(true);
                  }}
                ></textarea>
                <small
                  style={
                    hostDesc.length < maxDescLength
                      ? { color: "#84FF81" }
                      : { color: "orange" }
                  }
                >
                  Char count: {hostDesc.length.toString()}
                </small>
              </div>
              <div>
                <small>
                  This will be displayed in the app where users browse and
                  select the host they want to listen to. Supports markdown.
                  Limit to {maxDescLength.toString()} chars.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Host Attributes & Topics</h3>
            <div className="row">
              <div>
                <input
                  type="text"
                  value={hostGenreTag1}
                  style={{ marginRight: 8 }}
                  onChange={(value) => {
                    setHostGenreTag1(value.currentTarget.value);
                    setPendingSave(true);
                  }}
                />
                <input
                  type="text"
                  value={hostGenreTag2}
                  style={{ marginRight: 8 }}
                  onChange={(value) => {
                    setHostGenreTag2(value.currentTarget.value);
                    setPendingSave(true);
                  }}
                />
                <input
                  type="text"
                  value={hostGenreTag3}
                  onChange={(value) => {
                    setHostGenreTag3(value.currentTarget.value);
                    setPendingSave(true);
                  }}
                />
              </div>
              <div>
                <small>
                  These will be displayed in the app when browsing hosts to give
                  users a quick overview of what to expect from this host. Enter
                  up to 3 to give the best overview of your host.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>POI Types</h3>
            <div className="row" style={{ marginTop: 16 }}>
              <div>
                <MultiselectAccordionGooglePlacesTypes
                  onChange={handlePoiTypesChange}
                  selectedValues={selectedPoiTypes}
                />
              </div>
              <div>
                <small>
                  Select the maps categories to be used by this host. These will
                  define what POIs will be made available in map browsing views,
                  and used in the prompts.
                </small>
              </div>
            </div>
          </>
          {/* -----Audio------ */}
          <>
            <h3>Voice Model</h3>
            <div className="row">
              <div>
                <Select
                  value={selectedVoiceModel}
                  onChange={(newValue) => {
                    setSelectedVoiceModel(newValue);
                    setPendingSave(true);
                  }}
                  options={voiceModelOptions()}
                  styles={dropdownStyles}
                />
              </div>
              <div>
                <small>
                  This is the ElevenLabs AI model that will be used to generate
                  the voice of the host.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Host voice</h3>
            <div className="row">
              <div>
                <div className="side-by-side">
                  <div>
                    {voices.data && (
                      <Select
                        value={selectedVoice}
                        onChange={(newValue) => {
                          setSelectedVoice(newValue);
                          setPendingSave(true);
                        }}
                        options={voiceOptions()}
                        styles={dropdownStyles}
                      />
                    )}
                  </div>
                  <div>
                    {voices.data &&
                      selectedVoice &&
                      voices.data!.find(
                        (it) => selectedVoice!.value === it.voiceId.toString()
                      ) === undefined && <p>Could not preview this voice.</p>}
                    {selectedVoice &&
                      voices.data &&
                      voices.data!.find(
                        (it) => selectedVoice!.value === it.voiceId.toString()
                      ) && (
                        <audio
                          controls
                          src={
                            voices.data!.find(
                              (it) =>
                                selectedVoice!.value === it.voiceId.toString()
                            )!.previewUrl
                          }
                        >
                          Your browser does not support the audio element.
                        </audio>
                      )}
                  </div>
                </div>
              </div>
              <div>
                <small>
                  This identifies the ElevenLabs voice that will be used to
                  create the audible voice of this host.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Voice stability</h3>
            <div className="row">
              <div>
                <input
                  type="number"
                  min="0"
                  max="1"
                  step={0.1}
                  value={hostVoiceStability}
                  onChange={handleHostVoiceStabilityChange}
                  style={{ transform: "translateY(-4px)", marginRight: 8 }}
                />
                <input
                  type="range"
                  min="0"
                  step={0.1}
                  max="1"
                  value={hostVoiceStability}
                  onChange={handleHostVoiceStabilityChange}
                  style={{ width: "70%" }}
                />
              </div>
              <div>
                <small>
                  The stability slider determines how stable the voice is and
                  the randomness between each generation. Lowering this slider
                  introduces a broader emotional range for the voice. As
                  mentioned before, this is also influenced heavily by the
                  original voice. Setting the slider too low may result in odd
                  performances that are overly random and cause the character to
                  speak too quickly. On the other hand, setting it too high can
                  lead to a monotonous voice with limited emotion.
                </small>
              </div>
            </div>

            <h3>Voice Similarity</h3>
            <div className="row">
              <div>
                <input
                  type="number"
                  min="0"
                  step={0.1}
                  max="1"
                  value={hostVoiceSimilarityBoost}
                  onChange={handleHostSimilarityBoostChange}
                  style={{ transform: "translateY(-4px)", marginRight: 8 }}
                />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step={0.1}
                  value={hostVoiceSimilarityBoost}
                  onChange={handleHostSimilarityBoostChange}
                  style={{ width: "70%" }}
                />
              </div>
              <div>
                <small>
                  The similarity slider dictates how closely the AI should
                  adhere to the original voice when attempting to replicate it.
                  If the original audio is of poor quality and the similarity
                  slider is set too high, the AI may reproduce artifacts or
                  background noise when trying to mimic the voice if those were
                  present in the original recording.
                </small>
              </div>
            </div>

            <h3>Voice Style Exaggeration</h3>
            <div className="row">
              <div>
                <input
                  type="number"
                  min="0"
                  max="1"
                  step={0.1}
                  value={hostVoiceStyle}
                  onChange={handleHostVoiceStyleChange}
                  style={{ transform: "translateY(-4px)", marginRight: 8 }}
                />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step={0.1}
                  value={hostVoiceStyle}
                  onChange={handleHostVoiceStyleChange}
                  style={{ width: "70%" }}
                />
              </div>
              <div>
                <small>
                  With the introduction of the newer models, we also added a
                  style exaggeration setting. This setting attempts to amplify
                  the style of the original speaker. It does consume additional
                  computational resources and might increase latency if set to
                  anything other than 0. It’s important to note that using this
                  setting has shown to make the model slightly less stable, as
                  it strives to emphasize and imitate the style of the original
                  voice. In general, we recommend keeping this setting at 0 at
                  all times.
                </small>
              </div>
            </div>

            <h3>Use Speaker Boost</h3>
            <div className="row">
              <div>
                <label className="toggle-switch small">
                  <input
                    type="checkbox"
                    checked={hostSpeakerBoost}
                    onChange={handleHostSpeakerBoostChange}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <div>
                <small>
                  This is another setting that was introduced in the new models.
                  The setting itself is quite self-explanatory – it boosts the
                  similarity to the original speaker. However, using this
                  setting requires a slightly higher computational load, which
                  in turn increases latency. The differences introduced by this
                  setting are generally rather subtle.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Host Audio Sample</h3>
            <div className="row">
              <div>
                {selectedAudioFile == null && !host.data?.audioSampleUrl && (
                  <p style={{ margin: "10px 0px", color: "gray" }}>
                    No Audio Sample
                  </p>
                )}
                {selectedAudioFile != null && (
                  <audio controls src={URL.createObjectURL(selectedAudioFile)}>
                    Your browser does not support the audio element.
                  </audio>
                )}
                {selectedAudioFile == null && host.data?.audioSampleUrl && (
                  <>
                    <audio controls src={host.data!.audioSampleUrl}>
                      Your browser does not support the audio element.
                    </audio>
                    <a
                      className="download-button"
                      href={host.data!.audioSampleUrl}
                      download
                      target="_blank"
                    >
                      <button>Download</button>
                    </a>
                  </>
                )}
                <AudioPicker
                  onValidAudioSelected={handleValidAudioSelected}
                ></AudioPicker>
              </div>
              <div>
                <small>
                  This is a sample of the host's stories. Users can listen to it
                  when browsing hosts.
                  <br />
                  <br />
                  <b>Audio requirements</b>
                  <br />
                  Use mp3 format
                </small>
              </div>
            </div>
          </>
          {/* -----Global Attributes------ */}
          <>
            <h3>Text Model</h3>
            <div className="row">
              <div>
                <Select
                  value={selectedTextModel}
                  onChange={(newValue) => {
                    setSelectedTextModel(newValue);
                    setPendingSave(true);
                  }}
                  options={textModelOptions()}
                  styles={dropdownStyles}
                />
              </div>
              <div>
                <small>
                  This is the AI LLM model that will be used to generate the
                  stories for the host.
                </small>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Persona Definition</h3>
            <div className="row">
              <div>
                <textarea
                  style={{ minHeight: 500 }}
                  value={hostPrompt}
                  onChange={(value) => {
                    setPendingSave(true);
                    setHostPrompt(value.currentTarget.value);
                  }}
                ></textarea>
              </div>
              <div>
                <p>
                  <b>
                    Your prompt should include any direction on what your host
                    is an expert in, what they should look for and focus on in
                    their stories, and how they should present and construct
                    their stories.
                  </b>
                  <br />
                  <br />
                  You can adjust the prompts to your liking. Be descriptive and
                  clear. You can also motivate the AI to do your bidding.
                  <br />
                  <br />
                  There are three variables in the system prompt:
                  <span style={{ color: "#34A853" }}>
                    {" {"}city{"}"}
                  </span>
                  ,{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}
                    country{"}"}
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}pois{"}"}
                  </span>
                  .
                  <br />
                  <br />
                  The{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}city{"}"}
                  </span>{" "}
                  and{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}country{"}"}
                  </span>{" "}
                  variables give the AI some context about the location.
                  <br />
                  <br />
                  All variables are fetched from external data sources.
                  <br />
                  <br />
                  Note! The variables need to be inside the curly braces{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}
                    {"}"}
                  </span>{" "}
                  and their names need to be exactly as shown in the example
                  prompt. Even different capitalization will cause the variable
                  to not be replaced.
                </p>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Extract Rabbithole Extension Points</h3>
            <div className="row">
              <div>
                <textarea
                  style={{ minHeight: 500 }}
                  value={suggestionsPrompt}
                  onChange={(value) => {
                    setPendingSave(true);
                    setSuggestionsPrompt(value.currentTarget.value);
                  }}
                ></textarea>
              </div>
              <div>
                <p>
                  This prompt is used to extract N amount of topics from the
                  generated story. The topics are then presented to the user as
                  the options for follow up stories.
                  <br />
                  <br />
                  Variables in the prompt:
                  <span style={{ color: "#34A853" }}>
                    {" {"}n{"}"}
                  </span>
                </p>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>Rabbithole Continuation Prompt</h3>
            <div className="row">
              <div>
                <textarea
                  style={{ minHeight: 500 }}
                  value={followUpPrompt}
                  onChange={(value) => {
                    setPendingSave(true);
                    setFollowUpPrompt(value.currentTarget.value);
                  }}
                ></textarea>
              </div>
              <div>
                <p>
                  This prompt is used when the user selects a follow up topic
                  after the initial story is generated. This prompt guides the
                  AI to generate a story about a specific follow up topic that
                  is extracted from the previous story in the rabbit hole.
                  <br />
                  <br />
                  Variables in the prompt:
                  <span style={{ color: "#34A853" }}>
                    {" {"}follow_up_topic{"}"}
                  </span>
                </p>
              </div>
            </div>
            <hr style={{ clear: "both" }} />

            <h3>POI Web Search</h3>
            <small>
              Augment POIs with web search
              <label className="toggle-switch small">
                <input
                  type="checkbox"
                  checked={webSearchEnabled}
                  onChange={handleWebSearchEnabledChange}
                />
                <span className="slider"></span>
              </label>
            </small>

            <h3>Query template</h3>
            <div className="row">
              <div>
                <textarea
                  ref={QueryTemplateRef}
                  style={{
                    marginTop: 8,
                    minHeight: 80,
                    overflow: "hidden",
                  }}
                  value={webSearchQueryTemplate}
                  placeholder="Example: Crime reports at {poi.name} {poi.address}"
                  onChange={(value) => {
                    setPendingSave(true);
                    handleWebSearchQueryTemplateChange(value);
                  }}
                ></textarea>
              </div>
              <div>
                <small>
                  Enter a template to use for the search. Following template
                  variables are available:{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}poi.name{"}"}
                  </span>
                  ,{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}poi.address{"}"}
                  </span>
                  ,{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}poi.city{"}"}
                  </span>
                  ,{" "}
                  <span style={{ color: "#34A853" }}>
                    {"{"}poi.country{"}"}
                  </span>{" "}
                </small>
              </div>
            </div>

            <h3>Topic</h3>
            <div className="row">
              <div>
                <Select
                  value={selectedWebSearchTopic}
                  onChange={(newValue) => {
                    setPendingSave(true);
                    setSelectedWebSearchTopic(newValue);
                  }}
                  options={webSearchTopicOptions()}
                  styles={dropdownStyles}
                />
              </div>
              <div></div>
            </div>

            <h3>Include domains</h3>
            <div className="row">
              <div>
                <textarea
                  ref={IncludeDomainsRef}
                  style={{
                    marginTop: 8,
                    minHeight: 45,
                    overflow: "hidden",
                  }}
                  value={webSearchIncludeDomainsInput}
                  placeholder="example.com another.com"
                  onChange={(value) => {
                    setPendingSave(true);
                    handleWebSearchIncludeDomainsInputChange(value);
                  }}
                ></textarea>
              </div>
              <div>
                <small>
                  Enter a list of domains (urls) to include in the search.
                </small>
              </div>
            </div>

            <h3>Exclude domains</h3>
            <div className="row">
              <div>
                <textarea
                  ref={ExcludeDomainsRef}
                  style={{
                    marginTop: 8,
                    minHeight: 45,
                    overflow: "hidden",
                  }}
                  value={webSearchExcludeDomainsInput}
                  placeholder="example.com another.com"
                  onChange={(value) => {
                    setPendingSave(true);
                    handleWebSearchExcludeDomainsInputChange(value);
                  }}
                ></textarea>
              </div>
              <div>
                <small>
                  Enter a list of domains (urls) to exclude in the search.
                </small>
              </div>
            </div>
          </>
          {/* -----Surprise Me------ */}
          <>
            <h3>“Surprise me” Prompt</h3>
            <div className="row">
              <div>
                <textarea
                  style={{ minHeight: 500 }}
                  value={surprisePrompt}
                  onChange={(value) => {
                    setPendingSave(true);
                    setSurprisePrompt(value.currentTarget.value);
                  }}
                ></textarea>
              </div>
              <div>
                <p>
                  This prompt is used along with the host prompt when the user
                  selects “Surprise me!” in the app. It guides the AI to find
                  interesting stories about a number of close by POIs.
                  <br />
                  <br />
                  Variables in the prompt:
                  <span style={{ color: "#34A853" }}>
                    {" {"}pois{"}"}
                  </span>
                </p>
              </div>
            </div>
            {host.data && (
              <HostPlayerMap
                storyType={StoryType.SURPRISE_ME}
                dropdownStyles={dropdownStyles}
                host={host.data}
                pendingSave={pendingSave}
                selectedPoiTypes={selectedPoiTypes}
              />
            )}
          </>
          {/* -----POI Rabbithole------ */}
          <>
            <h3>Selected “POI” Prompt</h3>
            <div className="row">
              <div>
                <textarea
                  style={{ minHeight: 500 }}
                  value={selectedPoiPrompt}
                  onChange={(value) => {
                    setPendingSave(true);
                    setSelectedPoiPrompt(value.currentTarget.value);
                  }}
                ></textarea>
              </div>
              <div>
                <p>
                  This prompt is used, in addition of the host prompt, when the
                  user selects a distinct POI on the map. This prompt guides the
                  AI to generate a story specifically about that one specific
                  place.
                  <br />
                  <br />
                  Variables in the prompt:
                  <span style={{ color: "#34A853" }}>
                    {" {"}poi{"}"}
                  </span>
                </p>
              </div>
            </div>
            {host.data && (
              <HostPlayerMap
                storyType={StoryType.SELECT_POI}
                dropdownStyles={dropdownStyles}
                host={host.data}
                pendingSave={pendingSave}
                selectedPoiTypes={selectedPoiTypes}
              />
            )}
          </>
        </TabContainer>
      </div>
      <Modal
        isOpen={showPublishDialog}
        onClose={() => {
          setShowPublishDialog(false);
        }}
      >
        <HostStageItem
          text={"Draft"}
          selected={hostStage === "draft"}
          onClick={function (): void {
            if (hostStage !== "draft") {
              setHostStage("draft");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
        <HostStageItem
          text={"Staging"}
          selected={hostStage === "staging"}
          onClick={function (): void {
            if (hostStage !== "staging") {
              setHostStage("staging");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
        <HostStageItem
          text={"Production"}
          selected={hostStage === "prod"}
          onClick={function (): void {
            if (hostStage !== "prod") {
              setHostStage("prod");
              setPendingSave(true);
              setShowPublishDialog(false);
            }
          }}
        ></HostStageItem>
      </Modal>
    </div>
  );
}
export default HostPage;
