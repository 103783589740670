export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function processPCMData(arrayBuffer: ArrayBuffer) {
  const pcmData = new Float32Array(arrayBuffer.byteLength / 2);
  const dataView = new DataView(arrayBuffer);

  for (let i = 0; i < pcmData.length; i++) {
    pcmData[i] = dataView.getInt16(i * 2, true) / 32768;
  }
  return pcmData;
}

export function getSampleRate(outputFormat: string): number {
  const match = outputFormat.match(/pcm_(\d+)/);
  return match ? parseInt(match[1], 10) : 44100;
}

export function calculateDistance(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const R = 6371e3; // Radius of the Earth in meters
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // in meters
  return `${Math.round(distance)}m`;
}
