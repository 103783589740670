import React, { useState } from 'react';
import './TabContainer.css';

interface TabContainerProps {
    tabs: string[];
    children: React.ReactNode[];
    className?: string;
}

const TabContainer: React.FC<TabContainerProps> = ({ tabs, children, className }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className={`tab-container ${className || ''}`}>
            <div className="tab-header">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab-item ${index === activeIndex ? 'active' : ''}`}
                        onClick={() => setActiveIndex(index)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {children.map((child, index) => (
                    <div key={index} className={`tab-pane ${index === activeIndex ? 'active' : ''}`}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TabContainer;
